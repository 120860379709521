<template>
    <div class="van-nav-bar van-hairline--bottom" style="color: rgb(255, 255, 255) !important; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
        <div class="van-nav-bar__content">
            <div class="van-nav-bar__left"><router-link to="#" @click="$router.back()"><i class="van-icon van-icon-arrow-left van-nav-bar__arrow"></i></router-link></div>
            <div class="van-nav-bar__title van-ellipsis">{{title}}</div>
        </div>
    </div>
</template>
<script>
// import { ref } from "vue"
export default {
    //接收参数
    props: { 
        title: String 
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>

</style>