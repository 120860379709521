<template>
    <div  id="computer" class="QYZX">
        <div data-v-b093d892="" style="overflow: hidden; height: 100%;">
            <div data-v-b093d892="" class="main">
                <div data-v-b093d892="" class="homepage"  data-darkreader-inline-bgcolor="">
                
                    <div data-v-b093d892="">
                        <div data-v-b093d892="" style="text-align: center; height: 40px; display: flex;"><img data-v-b093d892="" src="@/assets/img/temp/head.8976d715.png" style="margin: auto; width: 100%; height: 40px;"></div>
                        <div data-v-b093d892="" class="swipe-box" style="margin-top: 0px;">

                            <!-- swiper -->
                            <div class="swiper">
                                <swiper
                                    :slidesPerView="1"
                                    :spaceBetween="30"
                                    :loop="true"
                                    :centeredSlides="true"
                                    :pagination="{ clickable: true }"
                                    :autoplay="{ delay: 3000, disableOnInteraction: false}"
                                    :navigation="false"
                                    :modules="modules"
                                >
                                    <swiper-slide><img src="@/assets/img/temp/4ab790e14459500d.png" alt="" /></swiper-slide>
                                    <swiper-slide><img src="@/assets/img/temp//779a5fc5897127a6.png" alt="" /></swiper-slide>
                                    <swiper-slide><img src="@/assets/img/temp//a9a212de5251be43.png" alt="" /></swiper-slide>
                                </swiper>
                                <div class="pagination"></div>
                                <div class="swiper-scrollbar"></div>
                            </div><!-- /end swiper -->

                        </div>
                   
                        <!-- 商品图 -->

                        <div data-v-b093d892="" class="mainbox"><!---->
                            <div data-v-b093d892="">
                                    <div data-v-b093d892="">
                                        <div data-v-b093d892="" class="product-item">
                                            <h3><span>Nhà Cửa - Đời Sống</span></h3>
                                            <div class="product-item-swiper">
                                                <div class="slide-btn-box">
                                                    <div data-v-137e75dc="" class="all_slide" style="width: 100%; height: 150px;">
                                                        <!-- 上一页 -->

                                                        <!-- <div data-v-137e75dc="" class="swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper" style="transition-duration: 0ms; transform: translate3d(-1083px, 0px, 0px);"> -->

                                                        <div data-v-137e75dc="" @click="prevHandler('dha')" class="swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper" id="dha">

                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/8edfb5a4213e1bbf.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Trung Nguyên Legend - Phin nhôm hoa văn màu đen Vĩ Nhân - có hộp giấ </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/5e8d6ef9734338ba.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> [Chính Hãng] Bàn Chải Đánh Răng Điện Zenyum Sonic T11 - Màu Xanh Dương- Công Nghệ Singapore </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/9ddcba75ccd36b8e.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Cây xương rồng nhồi bông có nhạc, biết nhảy biết múa và nhại tiếng, dễ dàng sử dụng và </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/d5c0e91a20ebd67e.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy Massage tan mỡ bụng Xung Điện EMS Beame BM-B48, Đai Massage Lưng Bụng Rung Nhiệt Giảm Đau Bụng Kinh </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/9d037a9dbc5ef5de.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Tượng Mèo Vẩy Tay Tự Động Trưng Bày Cửa Hàng, Quán Ăn, Địa Điểm Kinh Doanh - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/634b6b0766f89f5e.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Nhíp Lấy Tai Thương Hiệu PaKaSa Có Đèn Chuyên Dụng Có Kèm Pin - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/8edfb5a4213e1bbf.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Trung Nguyên Legend - Phin nhôm hoa văn màu đen Vĩ Nhân - có hộp giấ </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/5e8d6ef9734338ba.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> [Chính Hãng] Bàn Chải Đánh Răng Điện Zenyum Sonic T11 - Màu Xanh Dương- Công Nghệ Singapore </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/9ddcba75ccd36b8e.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Cây xương rồng nhồi bông có nhạc, biết nhảy biết múa và nhại tiếng, dễ dàng sử dụng và </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/d5c0e91a20ebd67e.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy Massage tan mỡ bụng Xung Điện EMS Beame BM-B48, Đai Massage Lưng Bụng Rung Nhiệt Giảm Đau Bụng Kinh </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/9d037a9dbc5ef5de.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Tượng Mèo Vẩy Tay Tự Động Trưng Bày Cửa Hàng, Quán Ăn, Địa Điểm Kinh Doanh - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/634b6b0766f89f5e.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Nhíp Lấy Tai Thương Hiệu PaKaSa Có Đèn Chuyên Dụng Có Kèm Pin - Hàng Chính Hãng </div>
                                                                </div>
                                                            </div>    
                                                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                        </div>
                                                        <!-- 下一页 -->
                                                        <div data-v-137e75dc="" class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-b093d892="">
                                        <div data-v-b093d892="" class="product-item">
                                            <h3><span>Đồng hồ và Trang sức</span></h3>
                                            <div class="product-item-swiper">
                                                <div class="slide-btn-box">
                                                    <div data-v-137e75dc="" class="all_slide" style="width: 100%; height: 150px;">
                                                        <div data-v-137e75dc="" @click="prevHandler('dhb')" class="swiper-button-prev swiper-button-prev1" tabindex="0" role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper"  id="dhb" style="transform: translate3d(-1083px, 0px, 0px); transition-duration: 0ms;">
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/c7186ae309c5b392.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dây Chuyền | Dây Chuyền Nữ Hình Con Bướm Xinh - XBDB46 - Bảo Ngọc Jewelry </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/dbf92b34536471ae.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dây chuyền hoa mặt trời BHDC158 </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/d9f4112ade94da10.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dây đồng hồ thép không gỉ dây mesh cao cấp YE </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/12b22f7b159b4c0c.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Đồng Hồ Nữ Citizen Đính Đá Swarovski Dây Thép Không Gỉ EW9816-51D - Mặt Xà Cừ </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/7769a017f42e849a.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Vòng Tay Charm Nữ  HNC001 </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/005442956bfe343d.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Bộ trang sức Ngọc Trai Thiên Nhiên T4 Mix Bảo Ngọc Jewelry  (Freesize) </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/c7186ae309c5b392.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dây Chuyền | Dây Chuyền Nữ Hình Con Bướm Xinh - XBDB46 - Bảo Ngọc Jewelry </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/dbf92b34536471ae.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dây chuyền hoa mặt trời BHDC158 </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/d9f4112ade94da10.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dây đồng hồ thép không gỉ dây mesh cao cấp YE </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/12b22f7b159b4c0c.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Đồng Hồ Nữ Citizen Đính Đá Swarovski Dây Thép Không Gỉ EW9816-51D - Mặt Xà Cừ </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/7769a017f42e849a.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Vòng Tay Charm Nữ  HNC001 </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/005442956bfe343d.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Bộ trang sức Ngọc Trai Thiên Nhiên T4 Mix Bảo Ngọc Jewelry  (Freesize) </div>
                                                                </div>
                                                            </div>    
                                                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                        </div>
                                                        <div data-v-137e75dc=""  @click="nextHandler('dhb')" class="swiper-button-next swiper-button-next1" tabindex="0" role="button" aria-label="Next slide"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div data-v-b093d892="">
                                        <div data-v-b093d892="" class="product-item">
                                            <h3><span>Làm Đẹp - Sức Khóe</span></h3>
                                            <div class="product-item-swiper">
                                                <div class="slide-btn-box">
                                                    <div data-v-137e75dc="" class="all_slide" style="width: 100%; height: 150px;">
                                                        <div data-v-137e75dc=""  @click="prevHandler('dhc')" class="swiper-button-prev swiper-button-prev1" tabindex="0" role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper"  id="dhc" style="transform: translate3d(-1083px, 0px, 0px); transition-duration: 0ms;">
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/89b2f467844388fb.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Hộp Đựng Mỹ Phẩm Trong Suốt Nhiều Ngăn, Đựng Son Môi Dụng Cụ Làm Đẹp, Nhiều Kiểu, Chất Liệu PET Dày Đẹp  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/ad13d60af9d66832.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Túi đựng mỹ phẩm túi đựng đồ du lịch bằng nhựa chống thấm WASHBAG gọn nhẹ tiện lợi - Chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/5cf9327351471b91.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dầu dừa tinh khiết mỹ phẩm Vietcoco 130ml  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/6d8f604036393cb3.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Bộ Hoa Hồng Sữa Dưỡng Hoàn Lưu Cao Whoo Hwanyu Trẻ Hoá Da 10 năm tuổi </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/2be46b28ddbbfce4.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Bộ Dưỡng Da Dưỡng Ẩm Ohui Miracle Moisture 2pcs Special Set Mẫu Mới </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/2d095f706548f034.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> KEM TRANG ĐIỂM BB ĐA TÁC DỤNG TÁCH DẦU CHỐNG NẮNG HÀN QUỐC QUEENIE 50ML - MỸ PHẨM CHÍNH HÃNG HÀN QUỐC</div>
                                                                </div>

                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/89b2f467844388fb.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Hộp Đựng Mỹ Phẩm Trong Suốt Nhiều Ngăn, Đựng Son Môi Dụng Cụ Làm Đẹp, Nhiều Kiểu, Chất Liệu PET Dày Đẹp </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/ad13d60af9d66832.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Túi đựng mỹ phẩm túi đựng đồ du lịch bằng nhựa chống thấm WASHBAG gọn nhẹ tiện lợi - Chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/5cf9327351471b91.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Dầu dừa tinh khiết mỹ phẩm Vietcoco 130ml  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/6d8f604036393cb3.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Bộ Hoa Hồng Sữa Dưỡng Hoàn Lưu Cao Whoo Hwanyu Trẻ Hoá Da 10 năm tuổi </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/2be46b28ddbbfce4.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Bộ Dưỡng Da Dưỡng Ẩm Ohui Miracle Moisture 2pcs Special Set Mẫu Mới </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/2d095f706548f034.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> KEM TRANG ĐIỂM BB ĐA TÁC DỤNG TÁCH DẦU CHỐNG NẮNG HÀN QUỐC QUEENIE 50ML - MỸ PHẨM CHÍNH HÃNG HÀN QUỐC </div>
                                                                </div>
                                                            </div>    
                                                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                        </div>
                                                        <div data-v-137e75dc=""  @click="nextHandler('dhc')" class="swiper-button-next swiper-button-next1" tabindex="0" role="button" aria-label="Next slide"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div data-v-b093d892="">
                                        <div data-v-b093d892="" class="product-item">
                                            <h3><span>Sản phẩm điện tử</span></h3>
                                            <div class="product-item-swiper">
                                                <div class="slide-btn-box">
                                                    <div data-v-137e75dc="" class="all_slide" style="width: 100%; height: 150px;">
                                                        <div data-v-137e75dc=""  @click="prevHandler('dhd')" class="swiper-button-prev swiper-button-prev1" tabindex="0" role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper"  id="dhd" style="transform: translate3d(-1083px, 0px, 0px); transition-duration: 0ms;">
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/e043f2fa94579e7c.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Điện thoại Tecno Spark Go 2023 4GB/64GB - Hàng chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/269c9c93dfea7f77.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Điện Thoại Masstel Hapi 30 3GB/32GB - Hàng Chính </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/b1d743670e95cbc9.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Điện thoại Nokia C30 2GB/32GB - Hàng chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/55b9ebede54b5f77.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Màn Hình Asus ProArt PA278QV 27' inch WQHD (2560 x 1440) - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/4097436dfb4e8bb3.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy Chiếu Mini BEECUBE X2 MAX GEN 4 2024 - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/a6e3b4bf3fa16789.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy tính bảng Kindle Fire HD10 2023 13th - 32Gb - Hàng chính hãng  </div>
                                                                </div>

                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/e043f2fa94579e7c.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Điện thoại Tecno Spark Go 2023 4GB/64GB - Hàng chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/269c9c93dfea7f77.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Điện Thoại Masstel Hapi 30 3GB/32GB - Hàng Chính </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/b1d743670e95cbc9.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Điện thoại Nokia C30 2GB/32GB - Hàng chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/55b9ebede54b5f77.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Màn Hình Asus ProArt PA278QV 27' inch WQHD (2560 x 1440) - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/4097436dfb4e8bb3.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy Chiếu Mini BEECUBE X2 MAX GEN 4 2024 - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/a6e3b4bf3fa16789.jpg" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy tính bảng Kindle Fire HD10 2023 13th - 32Gb - Hàng chính hãng  </div>
                                                                </div>
                                                            </div>    
                                                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                        </div>
                                                        <div data-v-137e75dc=""  @click="nextHandler('dhd')" class="swiper-button-next swiper-button-next1" tabindex="0" role="button" aria-label="Next slide"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div data-v-b093d892="">
                                        <div data-v-b093d892="" class="product-item">
                                            <h3><span>Laptop - Máy Vi Tính - Linh kiện</span></h3>
                                            <div class="product-item-swiper">
                                                <div class="slide-btn-box">
                                                    <div data-v-137e75dc="" class="all_slide" style="width: 100%; height: 150px;">
                                                        <div data-v-137e75dc=""   @click="prevHandler('dhe')" class="swiper-button-prev swiper-button-prev1" tabindex="0" role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper"  id="dhe" style="transform: translate3d(-1083px, 0px, 0px); transition-duration: 0ms;">
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/91cb869069437037.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Laptop HP 15s fq5228TU 8U240PA (Core i3 1215U/ 8GB/ 512GB SSD/ Intel UHD Graphics/ 15.6inch Full HD/ Windows 11 Home/ Blue/ Vỏ nhựa) - Hàng Chính Hãng - Bảo Hành 12 Tháng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/e074f8dacfd08d60.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Màn Hình Dell UltraSharp U2724DE 27 inch ( 2K/IPS/120Hz/5ms ) - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/c49f225eeafa6e24.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy quét tài liệu Canon DR C225II hàng chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/4c961693a4106c5d.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Laptop ASUS Zenbook 14 OLED UX3405MA-PP475W (Intel Core Ultra 9 Processor 185H | 32GB | 1TB | Intel Arc | Win 11 | Xanh </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/26a63989e2f781f9.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Đồng Hồ Nữ Citizen Đính Kim Cương Dây Thép Không Gỉ EM0327-50D - Mặt Xà Cừ (Sapphire)  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/bb52c3db90509d89.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Đồng Hồ Nữ Bulova Đính Đá Swarovski Dây Thép Không Gỉ 96L257 - Mặt Xà Cừ </div>
                                                                </div>

                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="3" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/91cb869069437037.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Laptop HP 15s fq5228TU 8U240PA (Core i3 1215U/ 8GB/ 512GB SSD/ Intel UHD Graphics/ 15.6inch Full HD/ Windows 11 Home/ Blue/ Vỏ nhựa) - Hàng Chính Hãng - Bảo Hành 12 Tháng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide" data-swiper-slide-index="4" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/e074f8dacfd08d60.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Màn Hình Dell UltraSharp U2724DE 27 inch ( 2K/IPS/120Hz/5ms ) - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-prev" data-swiper-slide-index="5" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/c49f225eeafa6e24.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy quét tài liệu Canon DR C225II hàng chính hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/4c961693a4106c5d.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Laptop ASUS Zenbook 14 OLED UX3405MA-PP475W (Intel Core Ultra 9 Processor 185H | 32GB | 1TB | Intel Arc | Win 11 | Xanh </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/26a63989e2f781f9.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Đồng Hồ Nữ Citizen Đính Kim Cương Dây Thép Không Gỉ EM0327-50D - Mặt Xà Cừ (Sapphire)  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/bb52c3db90509d89.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Đồng Hồ Nữ Bulova Đính Đá Swarovski Dây Thép Không Gỉ 96L257 - Mặt Xà Cừ </div>
                                                                </div>
                                                            </div>    
                                                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                        </div>
                                                        <div data-v-137e75dc=""  @click="nextHandler('dhe')" class="swiper-button-next swiper-button-next1" tabindex="0" role="button" aria-label="Next slide"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div data-v-b093d892="">
                                        <div data-v-b093d892="" class="product-item">
                                            <h3><span>Điện Tử - Điện Lạnh</span></h3>
                                            <div class="product-item-swiper">
                                                <div class="slide-btn-box">
                                                    <div data-v-137e75dc="" class="all_slide" style="width: 100%; height: 150px;">
                                                        <div data-v-137e75dc="" @click="prevHandler('dhf')" class="swiper-button-prev swiper-button-prev4"  role="button" aria-label="Previous slide"></div>
                                                        <div data-v-137e75dc="" class="swiper-container swiper-container-initialized swiper-container-horizontal"> 
                                                            <div class="swiper-wrapper"  id="dhf" style="transition-duration: 0ms; transform: translate3d(-1083px, 0px, 0px);">
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/8d4499118b69a813.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> [CỬA HÀNG CHÍNH HÃNG] Tủ Đông Mát 6 Cánh Quạt Gió  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/f5aa91071faf01f4.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext">  Máy Rửa Chén Độc Lập Bosch HMH.SMS6ECI93E (Series 6) - 13 Bộ - 8 Chương trình rửa - Hàng Chính Hãng - Chỉ Giao HCM </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/c086178a60d162cb.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Tủ lạnh Electrolux Inverter 619 lít ESE6645A-BVN - Hàng chính hãng [Giao hàng toàn quốc] </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-active"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/76948c645943359a.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Google Tivi Mini LED Sony 4K 85 inch XR-85X95K - Model 2022 </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate-next"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/b5bfcf8dbbf11905.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy rửa bát nhà hàng 2 bồn công nghệ siêu âm siêu sạch Rama SA2B - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/8c2ba00a81ba9744.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy làm đá viên tinh khiết HA180 - Hàng Chính Hãng </div>
                                                                </div>

                                                                <div data-v-137e75dc="" class="swiper-slide"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/8d4499118b69a813.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> [CỬA HÀNG CHÍNH HÃNG] Tủ Đông Mát 6 Cánh Quạt Gió  </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/f5aa91071faf01f4.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext">  Máy Rửa Chén Độc Lập Bosch HMH.SMS6ECI93E (Series 6) - 13 Bộ - 8 Chương trình rửa - Hàng Chính Hãng - Chỉ Giao HCM </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-prev"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/c086178a60d162cb.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Tủ lạnh Electrolux Inverter 619 lít ESE6645A-BVN - Hàng chính hãng [Giao hàng toàn quốc] </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-active"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/76948c645943359a.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Google Tivi Mini LED Sony 4K 85 inch XR-85X95K - Model 2022 </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate swiper-slide-next"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/b5bfcf8dbbf11905.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy rửa bát nhà hàng 2 bồn công nghệ siêu âm siêu sạch Rama SA2B - Hàng Chính Hãng </div>
                                                                </div>
                                                                <div data-v-137e75dc="" class="swiper-slide swiper-slide-duplicate"  style="width: 110.333px; margin-right: 10px;">
                                                                    <div data-v-137e75dc="" class="imgbox"><img data-v-137e75dc="" src="@/assets/img/temp/8c2ba00a81ba9744.png" class="productimg"></div>
                                                                    <div data-v-137e75dc="" class="productboxtext"> Máy làm đá viên tinh khiết HA180 - Hàng Chính Hãng </div>
                                                                </div>
                                                            </div>    
                                                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                                            <div data-v-137e75dc=""  @click="nextHandler('dhf')" class="swiper-button-next swiper-button-next4"  role="button" aria-label="Next slide"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <marquee />

                                    <div data-v-b093d892="" class="title"><img data-v-b093d892="" src="@/assets/img/temp/homeicon.97acc629.png">Đối tác </div>
                                    <div data-v-b093d892="" class="contsB">
                                        <div data-v-b093d892="" class="imgLi"><img data-v-b093d892="" src="@/assets/img/temp/1.6e3caa45.png"></div>
                                        <div data-v-b093d892="" class="imgLi"><img data-v-b093d892="" src="@/assets/img/temp/2.2a833197.png"></div>
                                        <div data-v-b093d892="" class="imgLi"><img data-v-b093d892="" src="@/assets/img/temp/3.19375762.png"></div>
                                        <div data-v-b093d892="" class="imgLi"><img data-v-b093d892="" src="@/assets/img/temp/4.7ddf305e.png"></div>
                                        <div data-v-b093d892="" class="imgLi"><img data-v-b093d892="" src="@/assets/img/temp/5.fafbf248.png"></div>
                                        <div data-v-b093d892="" class="imgLi"><img data-v-b093d892="" src="@/assets/img/temp/6.fdc05ab3.png"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            <BottomMenu :place="1" />
        </div>
    </div>
</template>


<script>

import BottomMenu from "@/components/computer/BottomMenu.vue"
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
// import { ref } from 'vue'
// 轮播图
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue"
// Import Swiper styles
// 引入swiper样式，对应css 如果使用less或者css只需要把scss改为对应的即可
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
// setup 语法糖只需要这样创建一个变量就可以正常使用分页器和对应功能，如果没有这个数组则无法使用对应功能
import { Autoplay, Navigation, Pagination } from "swiper/modules"

import marquee from "@/components/SimpleMarqueePc.vue"
// import mainMenu from "@/components/computer/MainMenu.vue"
import postPages from "@/assets/js/postPages"
// import Carousel from '@/components/CarouselList'
import tool from '@/assets/js/tool'
import $ from 'jquery'

export default {
    components: {
        //   marquee,
        Swiper,
        SwiperSlide,
        marquee,
        // mainMenu,
        // Carousel,
        BottomMenu
    },
    setup() {
        const onSlideChange = () => {
            console.log("slide change");
        }
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false
        return {
            onSlideChange,
            modules: [Autoplay, Navigation, Pagination],
            token
        }
    },
    data() {
          return {
              lang: '',
              languages: {
                  'vi-vn': 'Tiếng Việt',
                  'zh-cn': '中文',
              },
              showDropDown: false,
              slide: 0,
              api: {
                commodity: [],
              },

              dha: 0,
              dhb: 0,
              dhc: 0,
              dhd: 0,
              dhe: 0,
              dhf: 0,
              timeoutObj: false,
              timeout: 4000,
          }
    },
    mounted() {

        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 

        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 初始化页面的数据
        this.getData()
        this.slideCommodity()
        return {}
    },
    methods: {

        // 用户点击，触发切换语言请求。
        // 记录下用户选择的语言，路由跳转一下。
        switchLang(lang) {
            localStorage.setItem('lang', lang) // 存储
            return this.$router.go(0) // 刷新本页
        },
        toggleDrop() {
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },

        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&lang=${this.lang}`

            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/commodity6', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.api.commodity = res.data.items
                } else {
                    layer.msg(res.msg)
                }
            })
        }, // End getData()


        // 上一批商品图
        // 要将商品图从左侧移动到右则，就要确保左侧永远有商品图。
        // 所以左侧的商品图座标，应该随时保证在 -720 
        prevHandler(id) {
            let eId = $('#'+id)
            if (0==this[id]) this[id] = -720
            else this[id] += 360 // 每次触发 从右向左移动一次 要点上一批，那初始点在的位置，前方要有资源。
            // 
            // console.log( '上一批 ' + id )
            
            eId.css({ "transform": "translate3d("+this[id]+"px, 0px, 0px)", "transition": "all .5s" })
            // 要点上一批，那初始点在的位置，前方要有资源。
            // console.log( '上一批 ' + this.dha )
            if (0==this[id]) {
                // 第一批其实就是二批商品，所以当滑到第到一批时，要将座标移回第三批座标，这样就可以永远从左向右移动。因为左边什么时候都有内容。
                this[id] = -720
                setTimeout(
                    function lunchMenu(o, px) {
                        o.css({ "transform": "translate3d("+px+"px, 0px, 0px)", "transition": "all .0s" })
                    }, 
                    500, 
                    eId, 
                    this[id]
                )
            }
        },
        // 下一批商品图
        // 初始是 0 到-360是从右向左移动 
        // 再次向右 则是 -360 + -360
        nextHandler(id) {
            let eId = $('#'+id)
            this[id] += -360 // 每次触发 从右向左移动一次
            // this.dha += -360 // 每次触发 从右向左移动一次
            eId.css({ "transform": "translate3d("+ this[id] +"px, 0px, 0px)", "transition": "all .5s" })
            if (-720==this[id]) {
                // 第三批其实就是第一批商品，所以当滑到第到三批时，要将座标移回第一座标，这样就可以永远从右向左移动。因为右边什么时候都有内容。
                this[id] = 0
                setTimeout(
                    function lunchMenu(o, px) {
                        o.css({ "transform": "translate3d("+px+"px, 0px, 0px)", "transition": "all .0s" })
                    }, 
                    500, 
                    eId, 
                    this[id]
                )
            }
        },
        // 执于商品展示区自动滑动
        // 商品的滑动 要在后端数据完成加载的前提下 所以商器加载完成要有一个标识
        slideCommodity() {
            const startTime = () => {
              this.timeoutObj = setTimeout(() => {
                this.nextHandler('dha')
                this.nextHandler('dhb')
                this.nextHandler('dhc')
                this.nextHandler('dhd')
                this.nextHandler('dhe')
                this.nextHandler('dhf')

                
                startTime()
              }, this.timeout)
            }
            startTime()
        },
    } // End methods(...)

}
</script>

<!-- <style scoped src="../../public/css/main.min.css"></style> -->
<style lang="scss" scoped>
    .top-box-bg {
        background-image: url('/public/upload/600x55.png');
        background-repeat: no-repeat; /** 图片不要平铺 */
        background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
    }
    // .maxW {max-width: 600px;}

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 27px;
        height: 44px;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
        background-size: 27px 44px;
        background-position: 50%;
        background-repeat: no-repeat
    }
    // .swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled {
    //     opacity: .35;
    //     cursor: auto;
    //     pointer-events: none
    // }

    // .swiper-button-next {
    //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
    //     left: 10px;
    //     right: auto
    // }

    // .swiper-button-next.swiper-button-black {
    //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E")
    // }

    // .swiper-button-next.swiper-button-white {
    //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E")
    // }

    // .swiper-button-prev {
    //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
    //     right: 10px;
    //     left: auto
    // }

    .swiper-button-prev:after, .swiper-button-next:after {
        font-family: swiper-icons;
        font-size: 12px;
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
    }
</style>