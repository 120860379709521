
<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div>
            <div class="van-nav-bar van-hairline--bottom" style="color: rgb(255, 255, 255) !important; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
                <div class="van-nav-bar__content">
                    <div class="van-nav-bar__left"><router-link to="#" @click="$router.back()"><i class="van-icon van-icon-arrow-left van-nav-bar__arrow"></i></router-link></div>
                    <div class="van-nav-bar__title van-ellipsis">{{title}}</div>
                </div>
            </div>
            <div class="van-cell-group van-hairline--top-bottom">
                <router-link to="/edit/password">
                <div role="button" tabindex="0" class="van-cell van-cell--clickable">
                    <div class="van-cell__title"><span class="custom-title">{{ $t('revision.password') }}</span></div>
                    <div class="van-cell__value"><span>{{ $t('text.revision') }}</span></div>
                    <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
                </div>
                </router-link>
                <router-link to="/withdraw/password">
                <div role="button" tabindex="0" class="van-cell van-cell--clickable">
                    <div class="van-cell__title"><span class="custom-title">{{ $t('revision.withdrawalPassword') }}</span></div>
                    <div class="van-cell__value"><span>{{ $t('text.revision') }}</span></div>
                    <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
                </div>
                </router-link>
                <router-link to="/withdraw/account">
                <div role="button" tabindex="0" class="van-cell van-cell--clickable">
                    <div class="van-cell__title"><span>{{ $t('revision.withdrawalAccount') }}</span></div>
                    <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
                </div>
                </router-link>
            </div>
            <router-link to="/computer/user">
                <button  class="van-button van-button--info van-button--normal" style="width: 80%; margin-top: 65px; border-radius: 15px;"><div class="van-button__content"><span class="van-button__text">{{ $t('but.continue') }}</span></div></button>
            </router-link>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import tool from '@/assets/js/tool'
export default {
    components: {
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        this.title = this.$t('page.revision') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    }
}
</script>
<style lang="scss" scoped>

</style>