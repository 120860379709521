<template>

  <router-view v-wechat-title="$route.meta.title" />

</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
    // 判断是否是手机端
    // if(!this._isMobile()){
    //     // 不是手机端则跳转到PC端
    //     this.$router.replace("/computer")
    // }
    // 本项目只有PC端
    this.$router.replace("/computer")
  },
  methods:{
    // 判断是否是移动端
    _isMobile(){
      return navigator.userAgent.match(/(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
