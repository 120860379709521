<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div>
            <BackNav :title="title" />
            <div style="overflow: hidden;">
                <div class="pd-top" style="overflow: hidden auto; margin-top: 50px;">
                    <div>
                        <div class="withdraw-box">
                            <div class="item-block">
                                <div>
                                    <p>Chúc mừng bạn đã đăng nhập thành công !</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import BackNav from "@/components/computer/BackNav.vue"
import tool from '@/assets/js/tool'
export default {
    components: {
        BackNav,
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.serve') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>