<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div>
            <BackNav :title="title" />
            <div>
                <div class="van-cell-group van-hairline--top-bottom">
                    <div class="title-changepwd">{{ $t('revision.realName') }}</div>
                    <div class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="text"  v-model="input.real_name" :placeholder="$t('placeholder.enterRealName')" class="van-field__control"></div>
                        </div>
                    </div>
                    <div class="title-changepwd">{{ $t('revision.userNAME') }}</div>
                    <div class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="text"  v-model="input.bank_user" :placeholder="$t('placeholder.enterPhoneNumber')" class="van-field__control"></div>
                        </div>
                    </div>
                    <div class="title-changepwd">{{ $t('revision.bankAccount') }}</div>
                    <div class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="text"  v-model="input.bank_card" :placeholder="$t('placeholder.bankAccount')" class="van-field__control"></div>
                        </div>
                    </div>
                </div>
                <div class="van-cell-group van-hairline--top-bottom">
                    <div class="title-changepwd">{{ $t('revision.bankName') }}</div>
                    <div role="button" tabindex="0" class="van-cell van-cell--clickable van-field">
                        <!-- <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="text"  v-model="input.bank_user" name="picker" readonly="readonly" :placeholder="$t('placeholder.bankName')" class="van-field__control"></div>
                        </div> -->
                        <select v-model="input.bank_id" :class="css.input" >
                            <option v-for="(value, key) in banks" v-bind:key="key" :value="value.bank_id" class="text-ms">
                                {{ value.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <button @click="onSubmit" class="van-button van-button--info van-button--normal" style="width: 80%; margin-top: 65px; border-radius: 15px;"><div class="van-button__content"><span class="van-button__text">{{ $t('but.continue') }}</span></div></button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import postPages from "@/assets/js/postPages"
import BackNav from "@/components/computer/BackNav.vue"
import tool from '@/assets/js/tool'
export default {
    components: {
        BackNav,
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            banks: [],
            input: {
                real_name: '',
                bank_user: '',
                bank_card: '',
                bank_id: '',
            },
            css: {
                h1: 'text-xs-xs pl-5 font-semibold text-slate-700 text-left',
                input: 'block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs',
                but: 'submit-button text-xs text-white px-1 py-2 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors',
            }
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.accountsCanBeReceived') // 重置 setup 中的 title 要在重设置了语言之后

        this.getSelect()
        return {}
    },
    methods: {
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/banks', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                console.log(res)
                // const data = toRaw(res)
                this.banks = res.data.list
            })
        },
        onSubmit() {
            axios.post(
                '/api/m/edit/bank', 
                {
                    real_name: this.input.real_name,
                    bank_user: this.input.bank_user,
                    bank_card: this.input.bank_card,
                    bank_id: this.input.bank_id,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    this.$router.push({
                        path: '/user',
                        query: {
                            msg: ''
                        }
                    })
                } else {
                    layer.msg( this.$t('input.'+response.data.data.id) + ' ' + response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()
    }
}
</script>
<style lang="scss" scoped>

</style>