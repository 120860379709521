<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div>
            <BackNav :title="title" />
            <div style="overflow: hidden;">
                <div class="pd-top" style="overflow: hidden auto; margin-top: 50px;">
                    <div>
                        <div class="withdraw-box">
                            <div class="item-block">
                                <div><p>- Mua hàng trực tuyến mang lại sự tiện lợi, chủ động, lựa chọn đa dạng, với các dịch vụ hỗ trợ mua hàng, bán hàng, thanh toán an toàn, giao hàng chuyên nghiệp,<br>
Pinggo.vn&nbsp; không ngừng nỗ lực, cải tiến đáp ứng nhu cầu mua sắm cho người tiêu dùng.<br>
- Sản phẩm của PingGo cam kết chính hãng từ các nhà cung cấp, nhãn hàng với giá cả cạnh tranh. Khi đặt hàng tại PingGo bạn không cần phải lo lắng về chất lượng vì sản phẩm được đăng bán trên sàn đều đã được PingGo chọn lọc và kiểm tra cần thận.<br>
- Chúng tôi phục vụ 7 ngày trong tuần.<br>
-Quy định về công việc :<br>
- Trong quá trình làm việc các thành viên phải làm theo trình tự và đúng với quy định ,yêu cầu của hệ thống .<br>
- Pinggo .vn Xin Trân Trọng Cảm Ơn Các Thành Viên Đã Chung Tay Góp Sức Đồng Hành Cùng Công Ty Cổ Phần Pinggo&nbsp;.</p>
                                    <h5 data-v-00f1cf20="">&nbsp;</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import BackNav from "@/components/computer/BackNav.vue"
import tool from '@/assets/js/tool'
export default {
    components: {
        BackNav,
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.guide') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>