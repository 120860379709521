<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div style="position: relative;">
            <div class="leftreturn">
                <router-link to="#" @click="$router.back()">
                    <i class="van-icon van-icon-arrow-left" style="color: rgb(13, 194, 83); font-size: 30px; --darkreader-inline-color: #35b668;" data-darkreader-inline-color=""></i>
                </router-link>
            </div>
            <div class="login-block" style="top: 2rem;">
                <div class="titlePage" style="margin-top: 20px;"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('but.register') }}<!--注册--></font></font></div>
                <div class="login-box">
                    <div>
                        <div>
                            <!-- 使用电话号码做为帐号 -->
                            <span class="title">{{ $t('title.phone') }}</span>
                            <div class="field van-cell van-field">
                                <div class="van-field__left-icon"><i class="icon"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAoCAYAAACb3CikAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAPbSURBVHjazJZLTJxVFIC/e+4/DCCIaSxNQdSFJm0l1loXmjRlY4yJSFKMCQELC1La2DYsjMumGtdGSVxotCJWITUpjS5Mim360KhNbdOYIBvaVFpKSTUZpzAwj3uvC+aHYegMMzClc5KT/9xHzvnufx/nKPKQhu5x9dTOmoe8IM9LgF3AdqAGcC7BhPK4DJyITXFp9Pyt6XM9tS5X3yrXiZ3HXWlpJTuAw8CObHOt41fg/ci/9ue+Vj1TMJCuH1yVDnJQFIcBTwk4m+Ikre3zAB/M3uXjI2+o0KpBDpxy5cB7wLusQJzlo0SMQ581quls8yQHX7uB/cqDVIXlbeWBlLAvUErHqv7I/iH3khdkwDqeWLICBdZlttO+Yy5B2ycvq19W9Ee8IO3WUStqzmGq+kEz2WnfGh1g94q25sApVwM8p/Xc4fTVP5z30ixjnhK2Hjzr6vIG8YLUSwnr5/fQSwnkpfUtnIfF++4tsqu1pj5jvIyHx6MGqExztsRWXubgae2KpM/8QIAAoEVRELEOLSpzvIwDNkZYCbOGgknUQDh/EMcohhBQWyCQkI1xNW+Q8C37V8WjMgY8UyCQsUSM4bxvTV+rnjFxTpo40yYOvlq3YGfrS9OIiTOU7ZnP+qBFQqbXGoacA2vm1EQhte33+Xb6WFJ/ioTMF6tKep3HXT0wCDydnmmVLEpui7JxSv9VZ2n+8k3156rLgI5+s1NEHQUeB1Ba4cxccvFtpRdcpYzddMa197bImeVi5JJ96WvV542xrwNDqYFSbWfcvCbltDOuMReIvCo0gLe+ilcFSvWrwNvAi8mF+D5cUn8HPp2djv840BkM5eo773ezoXtc1W2r9nSJVIuorcBGQFnrJoAr1y/cngRMPvVqUYnKsAUBCagntZaHrXVB59yqMo5SyomoqDE2HL0b//vYvrLYsiAd/WabiHoFaAG2ACUFWnQMGAG+A072tsile4I090wFqzaUtwHvJAHup4wAH/43GflmsLsiOg+ShOgB9uR6pQtRGQBHrHXdfa16Rjd0j8vGTevagUPL1CcFP5/WumeBm488tveK6ug360TUZVhaqa+R3IhFEttFRO16gBAAdSXlXrNY6xqL4Bl5TUTUpiIA2SxAeRGAlAlwpwhA7ghwsQhA/hBr3YkiABmU2XDsLND/ACGORULR0wqg/dvEZq3le78uXUO5BjT1tsiwAHzd5o0AXcDoWkJY6/b0tsjwopr12m8T54yxTcBRIHEfAQwwADRdv3D7TMZ6pLlnKli5vmyLiOoCXgA2FCAZGmASuGiM/Xzqn9lhP/378v8A7iWd/s1E5ZIAAAAASUVORK5CYII=" class="van-icon__image"></i></div>
                                <div class="van-cell__value van-cell__value--alone van-field__value">
                                    <div class="van-field__body"><input type="text" v-model="form.account" :placeholder="$t('placeholder.pleaseEnterYourPhoneNumber')" class="van-field__control"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="title">{{ $t('title.password') }}</span>
                            <div class="field van-cell van-field">
                                <div class="van-field__left-icon"><i class="icon"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAmCAYAAACh1knUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAARBSURBVHjaxJhRbBRVFIa/c3d2u9sGSjEIJhLii8EQQ6FhQdoHFE1jbIJPWGKDMS0u8UFiiL6S6pPRKL4Ya6hIbDDR+CAxilEjBlYpsRVF2wpElCKtYBAspbvdnXt8cLfdLtN2ZirpSSaZOXPvf//733PnnDuCD9vfo4ucKE0ibANqVakWmdpGFURAFRXhEnAc6Br9J/N1qj5xY7YxZLYG7/6om0yEF4EGAppaFHhfDO2Pr5L+0EQO9mkKeEmVapiYMUU1VAsgMnk/Tbtzakm13CufByZysE/XAl8ANWWvMkB2FjEqgHiZ72Q+xyPbV8tFrw6Ol3NPV48R4bkyElaVj4H3VDnv1c8YsBZEWCFCM9AEmMLr2miMx/Z09extb6lTX4p0ndK7Iw69QFVRXmvZO/J35vlUfSLnJz460mPRhYvjL4uwq2QZBzI3WPNknWRumoSnTFHWiZAo2RnDxtDplwRAqj6RE2EfMFyMGxHuqlzAck81PQPHsBQQMVC4hoEhgtuQGIZLcMS6LPNNBHBMBCkqIoJ1XWxQFoU+tqBG8XL8KyLe92GsSGBiwIh3O08iqvyvVopn3WmWwMtp3bkrMd3A1gYhYqcSmYtCrusPy7ci2bHAsUpm1BKLmzkRyYkpW2bVTPDY0IwqWhYj2RlzzTu9Wgc8AaxSZTlMfnhEGFVlAMj72SUlAzsirFSlqsgN+AUYBI66efdA23rn0gSRzhPudhHzOrBocjZWJ8GNzG3X/IflgdOrare1JiOnZV93vlFEPgAWMD/2ZXZsdIsRkWfnkQTAAxWJqkcNsI75NQGaHT/l4gx2Hvi28IVuAO4IF0OaMMBISBKHgPtbk5Hm1mRkq7XuZuCrcPlIrhjgzxB9r6rq7tZk5NeiY8eGWL+q7go5sdMG+C1Ex5PAHx7+s8D3IZbmmAF6QhCJu/ncTbFV8FUExLoCnDJAOgSRtU40trHcGXGiDwFrA2L95OZz14217hngXMDOMeDVt77Jrio6Ok+4q0XkFSAaEOv4xXN9182Fs6f+UtVDIVS5x0QiG0vWOamqKwJi5IAj7S11rmlvqXOBT/wktDLLi0i2ZAtmRcQNiNGXG898N1EGuPlctxONdQP1AYEqO9JjlcX7oJsF+HBnQ9XlKWXAvu58UyH5xf1WgcDvwKXC8+3AihlOBuXWk8+Nb07VJ64BTNTUazZtOVN927KlQDJAjqgB7ixcNQHSxVWgbcd9sQHPI+ebx0aXRGPxA8DDtzDJZVX16bb1ztvTHid2NlRdzufGtwOf3iISI8Azg2d+2O/rEN6RHqt2orEXgFaYKPPmaj+r6u629c5ngX/UdJ5wtwJPARuBREgCfcBH1rpv7NgQuxD619Vrh4fiC2qWJEXkQWAzUDvLVrVAP3AUOGKtm56JgG8iZQrVqOpioFFEGoGVwELgeqE6T6vqYTefGwSuBfmN8e8Acq+YEbdjsMIAAAAASUVORK5CYII=" class="van-icon__image"></i></div>
                                <div class="van-cell__value van-cell__value--alone van-field__value">
                                    <div class="van-field__body"><input type="password" v-model="form.password" :placeholder="$t('placeholder.password')" class="van-field__control"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="title">{{ $t('title.confirmPassword') }}</span>
                            <div class="field van-cell van-field">
                                <div class="van-field__left-icon"><i class="icon"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAmCAYAAACh1knUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAARBSURBVHjaxJhRbBRVFIa/c3d2u9sGSjEIJhLii8EQQ6FhQdoHFE1jbIJPWGKDMS0u8UFiiL6S6pPRKL4Ya6hIbDDR+CAxilEjBlYpsRVF2wpElCKtYBAspbvdnXt8cLfdLtN2ZirpSSaZOXPvf//733PnnDuCD9vfo4ucKE0ibANqVakWmdpGFURAFRXhEnAc6Br9J/N1qj5xY7YxZLYG7/6om0yEF4EGAppaFHhfDO2Pr5L+0EQO9mkKeEmVapiYMUU1VAsgMnk/Tbtzakm13CufByZysE/XAl8ANWWvMkB2FjEqgHiZ72Q+xyPbV8tFrw6Ol3NPV48R4bkyElaVj4H3VDnv1c8YsBZEWCFCM9AEmMLr2miMx/Z09extb6lTX4p0ndK7Iw69QFVRXmvZO/J35vlUfSLnJz460mPRhYvjL4uwq2QZBzI3WPNknWRumoSnTFHWiZAo2RnDxtDplwRAqj6RE2EfMFyMGxHuqlzAck81PQPHsBQQMVC4hoEhgtuQGIZLcMS6LPNNBHBMBCkqIoJ1XWxQFoU+tqBG8XL8KyLe92GsSGBiwIh3O08iqvyvVopn3WmWwMtp3bkrMd3A1gYhYqcSmYtCrusPy7ci2bHAsUpm1BKLmzkRyYkpW2bVTPDY0IwqWhYj2RlzzTu9Wgc8AaxSZTlMfnhEGFVlAMj72SUlAzsirFSlqsgN+AUYBI66efdA23rn0gSRzhPudhHzOrBocjZWJ8GNzG3X/IflgdOrare1JiOnZV93vlFEPgAWMD/2ZXZsdIsRkWfnkQTAAxWJqkcNsI75NQGaHT/l4gx2Hvi28IVuAO4IF0OaMMBISBKHgPtbk5Hm1mRkq7XuZuCrcPlIrhjgzxB9r6rq7tZk5NeiY8eGWL+q7go5sdMG+C1Ex5PAHx7+s8D3IZbmmAF6QhCJu/ncTbFV8FUExLoCnDJAOgSRtU40trHcGXGiDwFrA2L95OZz14217hngXMDOMeDVt77Jrio6Ok+4q0XkFSAaEOv4xXN9182Fs6f+UtVDIVS5x0QiG0vWOamqKwJi5IAj7S11rmlvqXOBT/wktDLLi0i2ZAtmRcQNiNGXG898N1EGuPlctxONdQP1AYEqO9JjlcX7oJsF+HBnQ9XlKWXAvu58UyH5xf1WgcDvwKXC8+3AihlOBuXWk8+Nb07VJ64BTNTUazZtOVN927KlQDJAjqgB7ixcNQHSxVWgbcd9sQHPI+ebx0aXRGPxA8DDtzDJZVX16bb1ztvTHid2NlRdzufGtwOf3iISI8Azg2d+2O/rEN6RHqt2orEXgFaYKPPmaj+r6u629c5ngX/UdJ5wtwJPARuBREgCfcBH1rpv7NgQuxD619Vrh4fiC2qWJEXkQWAzUDvLVrVAP3AUOGKtm56JgG8iZQrVqOpioFFEGoGVwELgeqE6T6vqYTefGwSuBfmN8e8Acq+YEbdjsMIAAAAASUVORK5CYII=" class="van-icon__image"></i></div>
                                <div class="van-cell__value van-cell__value--alone van-field__value">
                                    <div class="van-field__body"><input type="password" v-model="form.confirm" :placeholder="$t('placeholder.confirmPassword')" class="van-field__control"></div>
                                </div>
                            </div>
                        </div>
                        <div><span class="title">{{ $t('title.inviteCode') }}</span>
                            <div class="field van-cell van-field">
                                <div class="van-cell__value van-cell__value--alone van-field__value">
                                    <div class="van-field__body"><input type="text" v-model="form.invite" :placeholder="$t('placeholder.invitation')" class="van-field__control"></div>
                                </div>
                            </div>
                        </div>
                        <div role="radiogroup" class="van-radio-group">
                            <div role="radio" tabindex="-1" aria-checked="false" class="van-radio" @click="agreement">
                                <div :class="css.agreement">
                                    <!-- van-radio__icon van-radio__icon--round van-radio__icon--checked -->
                                    <i class="van-icon van-icon-success"></i>
                                </div>
                                <span class="van-radio__label"><div style="float: left;">{{ $t('text.iAgreeWith') }} </div> &nbsp; <span class="regBtn" style="display: inline;">{{ $t('text.userAgreement') }}</span></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style="margin: 15px 0px 10px;"><button @click="runSubmit" class="van-button van-button--info van-button--normal van-button--round subbtn grayBg"><div class="van-button__content"><span class="van-button__text">{{$t('but.continue')}}</span></div></button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
// import topMenu from "@/components/computer/TopMenu.vue"
// import mainMenu from "@/components/computer/MainMenu.vue"
import axios from 'axios'
import { layer } from "vue3-layer"

import Message from '@/components/message'
import router from "@/router/index.js"
export default {
    components: {
        // topMenu,
        // mainMenu
    },
    setup() {
        // 显示失败信息
        const showMessage1 = () => {
            Message({
                msg: '34',
                type: 'success'
            })
        }
        // 显示成功信息
        const showMessage2 = () => {
            Message.error('45')
        }

        return {
            showMessage1,
            showMessage2
        }
    },
    data() {
        return {
            lang: '',
            form: {
                account: '',
                password: '',
                confirm: '',
                invite: '',
                agreement: false,
            },
            css: {
                agreement: 'van-radio__icon van-radio__icon--round',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        return {}
    },
    methods: {
        runSubmit() {
            // 检查是否同意用户协议，如果不同意，不给注册。
            if (!this.form.agreement) {
                layer.msg( this.$t('text.seeAgreement') )
                return false
            }
            // 提交数据到后端
            axios.post(
                '/api/register', 
                {
                    account: this.form.account,
                    password: this.form.password,
                    confirm: this.form.confirm,
                    invite: this.form.invite,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    localStorage.setItem('token', response.data.data.token)
                    router.push({
                        path: '/computer/user',
                        query: {
                            msg: 'reg account'
                        }
                    })
                } else {
                    // 从语言包中取出表单的标题
                    // let msg = ''
                    // if (response.data.data.id) msg = this.$t(`title.${response.data.data.id}`) + ' ' + response.data.data.error 
                    // else msg = response.data.msg 

                    // 把 "account 必须是数字" 中的 account 删除掉
                    // let errorTxt = response.data.data.error.replace(response.data.data.id, '')

                    let word = response.data.data.id
                    let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    let msg = this.$t(`input.${key}`) + ' ' + response.data.msg

                    layer.msg(msg)
                    // console.log(response.data)
                }
            })
            .catch((error) =>  {
                console.log(error)
            })
        },
        // 是否同意用户协议
        agreement() {
            // console.log('是否同意用户协议')
            if (!this.form.agreement) {
                this.form.agreement = true
                this.css.agreement = 'van-radio__icon van-radio__icon--round van-radio__icon--checked'
            }
            else {
                this.form.agreement = false
                this.css.agreement = 'van-radio__icon van-radio__icon--round'
            }
        }
    }

}
</script>
<style lang="scss" scoped>
</style>