export default function tool() {

    // 检查是否有令牌 因为这个系统 只允许登录后使用 所以可以用的页面就只有注册与登录了
    const checkToken = (router) => {
        if ( !localStorage.getItem('token') ){
            router.replace("/computer/login")
        } 
    }

    return { checkToken }
}