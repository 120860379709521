<template>
    <!--
    为了重写一个样式 
    -->
    <div class="Footer2">
        <div class="footerButs" style="margin-top: 8px;">
            <div class="footerBut">
                <router-link to="/computer" :class="selectedHome">
                    <div><i class="van-icon van-icon-shopping-cart-o"></i><p>{{ $t('footer.homePage') }}</p></div>
                </router-link>
            </div>
            <div class="footerBut">
                <router-link to="/computer/product" :class="selectedTask">
                    <div><i class="van-icon van-icon-notes-o"></i><p>{{ $t('footer.product') }}</p></div>
                </router-link>
            </div>
            <div class="footerBut">
                <router-link to="/computer/user" :class="selectedUser">
                    <div><i class="van-icon van-icon-friends-o"></i><p>{{ $t('footer.mine') }}</p></div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    // 接收参数
    props: { 
        place: String 
    },
    data() {
        return {
            // selectedHome: '',
            // selectedTask: '',
            // selectedUser: '',
        }
    },
    setup(props, context) {
        let selectedHome = ''
        let selectedTask = ''
        let selectedUser = ''
        switch(props.place) {
            case 1:
                selectedHome = 'selected'
                break;
            case 2: 
                selectedTask = 'selected'
                break;
            case 3:
                selectedUser = 'selected'
                break;
        }
        //attrs传递的参数    msg1: '我是父组件传递的信息1'
        console.log({ ...context.attrs })
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false
        return {
            token,
            selectedHome,  // 这里将 selectedHome 返回则 data(){...} 中不需要再声明 selectedHome 变量
            selectedTask,
            selectedUser
        }
    },

}
</script>

<style lang="scss" scoped>
.Footer2 {
    z-index: 999;
    background-color: #fff;
    position: fixed; // 脱离文档流定位
    bottom: 0;
    margin: 0 auto;  // 左右居中
    width: 375px;
    background-color: rgb(24, 26, 27);
    color: rgb(200, 195, 188);
    font-size: 22px;
    cursor: pointer;
    font-size: 14px;
}
.van-icon, .van-icon:before {
    display: inline-block;
}
.van-icon {
    position: relative;
    font: normal normal normal 14px/1 vant-icon;
    font: normal normal normal 14px/1 var(--van-icon-font-family, "vant-icon");
    font-size: 22px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding-bottom:1px;
}
.footerBut {
    float: left;
    width: 125px;
}
.Footer2 p {
    font-size: 12px;
    line-height: 2;
}
.Footer2 .selected {
    color: rgb(27, 156, 255);
}
</style>