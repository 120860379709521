<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div>
            <BackNav :title="title" />
            <div class="pd-top">
                <div>
                    <div>
                        <div class="detail-box">
                            <!-- <div class="header-text" v-show="show.setup2"> Số vốn lưu động: 137170850.00 VND </div> -->
                            <div class="main-box">
                                <div class="title-box" v-show="show.setup2">
                                    <div class="fz12 col-055">{{ $t('revision.bankAccount') }}</div>
                                    <div class="fz26 font-wgh col-fa0 mg-btm20">
                                        <input
                                            type="text"
                                            placeholder=""
                                            v-model="bank.number"
                                            disabled
                                        /> 
                                    </div>
                                    <div class="fz12 col-055">{{ $t('revision.userNAME') }}</div>
                                    <div class="fz26 font-wgh col-fa0 mg-btm20">
                                        <input
                                            type="text"
                                            placeholder=""
                                            v-model="bank.name"
                                            disabled
                                        /> 
                                    </div>
                                    <div class="fz12 col-055">{{ $t('revision.bankName') }}</div>
                                    <div class="fz26 font-wgh col-fa0 mg-btm20">
                                        <input
                                            type="text"
                                            placeholder=""
                                            v-model="bank.title"
                                            disabled
                                        /> 
                                    </div>
                                    <div class="fz12 col-055">{{ $t('revision.rechargeAmount') }}</div>
                                    <div class="fz26 font-wgh col-fa0 mg-btm20">
                                        <input
                                            type="text"
                                            placeholder=""
                                            v-model="input.amount"
                                            disabled
                                        /> 
                                    </div>

                                    <div class="my-2">
                                        <span class="text-xs">{{$t('text.tipRecharge')}}</span>
                                    </div>

                                    <router-link to="/user">
                                    <div class="btn-box">
                                        <div class="btn" style="line-height: 30px;">
                                            <div>{{ $t('revision.CompleteRecharge') }} </div>
                                        </div>
                                    </div>
                                    </router-link>

                                    

                                </div>
                                <div class="text-lef" v-show="show.setup1">
                                    <div class="fz14 font-wgh mg-lef15">{{ $t('page.recharge') }}</div>
                                    <div class="field-box">
                                        <div class="van-cell van-field field-item">
                                            <div class="van-cell__title van-field__label"><label id="van-field-4-label" for="van-field-4-input">VND</label></div>
                                            <div class="van-cell__value van-field__value">
                                                <div class="van-field__body"><input type="text"  v-model="input.amount" inputmode="decimal" id="van-field-4-input" :placeholder="$t('placeholder.rechargeAmount')" aria-labelledby="van-field-4-label" class="van-field__control van-field__control--right"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="text-lef">
                                    <div class="fz14 font-wgh mg-lef15"> Phương thức thanh toán </div>
                                    <div class="item-list">
                                        <div class="forItem-box">
                                            <div class="text " style="font-size: 12px;"> C_2_C </div>
                                        </div>
                                        <div class="forItem-box">
                                            <div class="text " style="font-size: 12px;"> MOMO </div>
                                        </div>
                                        <div class="forItem-box">
                                            <div class="text text-active" style="font-size: 12px;"> C_QR </div>
                                        </div>
                                        <div class="forItem-box">
                                            <div class="text " style="font-size: 12px;"> ZALO </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-lef">
                                    <div class="item-list"></div>
                                </div> -->
                            </div>
                            <div  @click="onSubmit" v-show="sendBut" class="btn-box">
                                <div class="btn" style="line-height: 30px;">
                                    <div>{{ $t('but.continue') }} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import BackNav from "@/components/computer/BackNav.vue"
import { ref } from 'vue'
import tool from '@/assets/js/tool'
export default {
    components: {
        BackNav,
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            sendBut: true,
            input: {
                amount: ''
            },
            show: {
                setup1: true,
                setup2: false,
            },
            bank: {
                number: '', 
                name: '',
                title: '',
            }
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        
        this.title = this.$t('page.recharge') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    },
    methods: {
        onSubmit() {
            console.log('xxx')
            // 点击提交时 会向后端发起创建充值订单的请求 服务器响应慢时 用户可能会多次点击 所以 当用户发起充值请求时 隐藏掉这个充值按钮
            this.sendBut = false
            // 后端生成一个充值订单 返回一个银行卡号 订单状态为等待用户付款状态
            // 等待用户充值 提示用户完成银行转帐之后 
            // 提示的页面 出现待完成的支付订单 可以删除
            axios.post(
                '/api/m/create/recharge', 
                {
                    amount: this.input.amount,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                console.log(response.data)
                if (0===response.data.code) {
                    // 不要跳转，就要本页面显示，接收充值的银行卡信息。
                    this.show.setup1 = false
                    this.show.setup2 = true
                    let banks = response.data.data.banks
                    this.bank.number = banks.number
                    this.bank.name = banks.name 
                    this.bank.title = banks.title 
                    console.log( this.bank )
                } else {
                    let word = response.data.data.id
                    let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })

        }
    }
}
</script>

<style lang="scss" scoped>

</style>