export default {
    lang: {
        'vi-vn': 'Tiếng Việt',
        'zh-cn': '中文'
    },
    page: {
        order: 'Đơn hàng',
        revision: 'Sửa đổi',
        guide: 'Hướng dẫn',
        serve: 'Dịch vụ',
        companyProfile: 'Giới thiệu công ty',
        onlineService: 'Dịch vụ trực tuyến',
        financialDetails: 'Vốn lưu động',
        recharge: 'Nạp tiền',
        withdraw: 'Rút tiền',
        accountsCanBeReceived: 'Những tài khoản có thể nhận được',
        password: 'Thay đổi mật khẩu đăng nhập',
        passwordFund: 'Thay đổi mật khẩu quỹ',
        SubmitOrder: 'Gửi đơn hàng',
        product: 'Gửi đơn hàng',
        OrderDetail: 'Chi tiết đơn hàng',
        out: 'từ bỏ',
    },
    link: {
        logout: 'từ bỏ',
    },
    side: {
        main: 'Nhà',
        trade: 'Quản lý thương mại',
        tradeOrder: 'Danh sách đơn hàng',
        tradeRecharge: 'Quản lý nạp tiền',
        tradeWithdraw: 'Quản lý rút tiền',
        tradeControl: 'Kiểm soát Thương mại',
        tradeAmount: 'Điều chỉnh số lượng',
        tradeStatistics: 'báo cáo thống kê',
        tradeReport: 'Báo cáo đại lý',
        tradeReportDaily: 'Báo cáo hàng ngày của đại lý',
        tradeReportMonthly: 'Báo cáo hàng tháng của đại lý',
        commodity: 'quản lý hàng hóa',
        commodityList: 'Danh sách hàng hóa',
        commoditySort: 'Phân loại hàng hóa',
        member: 'Quản lý thành viên',
        memberList: 'danh sách thành viên',
        memberSort: 'Cấp thành viên',
        memberLevel: 'Mức trung bình',
        content: 'Quản lý nội dung',
        contentNotice: 'Quản lý thông báo',
        contentText: 'Quản lý văn bản',
        contentAd: 'Băng chuyền trang chủ',
        contentLogo: 'LOGO',
        power: 'Quản lý quyền',
        powerList: 'Quyền truy cập',
        powerAdmin: 'người dùng hệ thống',
        system: 'quản lý hệ thống',
        systemMenu: 'Quản lý menu hệ thống',
        systemParameter: 'Cấu hình tham số hệ thống',
        systemLog: 'Nhật ký vận hành hệ thống'
    },
    but: {
        login: 'Đăng nhập',
        register: 'ĐĂNG KÝ',
        continue: 'Tiếp tục',
        recharge: 'Nạp tiền',
        withdraw: 'Rút tiền',
    },
    footer: {
        homePage: 'Trang Chủ',
        product: 'Sản phẩm',
        mine: 'Của tôi',
    },
    title: {
        account: 'TÊN ĐĂNG NHẬP',
        phone: 'Điện thoại',
        password: 'Mật khẩu',
        passkey: 'Mật khẩu',
        confirmPassword: 'Xác nhận mật khẩu',
        inviteCode: 'Mã mời',
        FundPassword: 'Mật khẩu quỹ',
    },
    placeholder: {
        account: 'Tên người dùng (kết hợp số và chữ cái)',
        password: 'Vui lòng nhập mật khẩu của bạn',
        agree: 'Tôi đồng ý với',
        userAgreement: 'thỏa thuận người dùng',
        user: 'Vui lòng nhập số điện thoại của bạn',
        confirmPassword: 'Vui lòng nhập lại mật khẩu',
        invitation: 'Nhập mã lời mời',
        rechargeAmount: 'Nhập số tiền cần nạp',
        withdrawalAmount: 'Nhập số tiền rút tiền (VND)',
        FundPassword: 'Nhập mật khẩu quỹ (VND)',
        firstSettingFund: 'Bạn không cần phải điền nó vào lần cài đặt đầu tiên.',
        pleaseEnterYourPhoneNumber: 'Vui lòng nhập số điện thoại của bạn',
        enterRealName: 'Nhập tên thật',
        enterPhoneNumber: 'Nhập số điện thoại',
        bankAccount: 'Tài khoản ngân hàng',
        bankName: 'Tên ngân hàng',
    },
    text: {
        // 我同意
        iAgreeWith: 'Tôi đồng ý với ',
        // 用户协议
        userAgreement: 'thỏa thuận người dùng',
        seeAgreement: 'Vui lòng đọc thỏa thuận người dùng trước và đồng ý!',
        workingCapital: 'Vốn lưu động',
        quickAction: 'Thao tác nhanh',
        amountOfWorkingCapital: 'Số vốn lưu động',
        currentFrozenBalance: 'Số dư đóng băng hiện tại',
        orderToday: 'Đơn hàng hôm nay',
        roseToday: 'Hoa hồng hôm nay',
        allOrders: 'Tất cả đơn hàng',
        totalCommission: 'Tổng hoa hồng',
        order: 'Đơn hàng',
        revision: 'Sửa đổi',
        guide: 'Hướng dẫn',
        serve: 'Dịch vụ',
        companyProfile: 'Giới thiệu công ty',
        onlineService: 'Dịch vụ trực tuyến',
        financialDetails: 'Chi tiết tài chính',
        rechargeAnnouncement: ' Lưu ý: Do lượng thông tin nạp quá lớn nên bạn phải kiểm tra kỹ số thẻ tài khoản trước khi nạp. Nền tảng này thay đổi số tài khoản theo thời gian. Nếu bạn có bất kỳ câu hỏi nào, vui lòng nhấp vào dịch vụ khách hàng trực tuyến！ ',
        rechargeLimit: 'nạp tiền trực tuyến1( 50k đến 300 triệu ) ',
        depositMethodSelect: 'Chọn phương thức nạp tiền',
        depositMethod: 'Phương thức thanh toán',
        serveText: 'Chúc mừng bạn đã đăng nhập thành công !',
        tipRecharge: 'Vui lòng hoàn tất chuyển khoản ngân hàng trong vòng 15 phút',
        AccountBalance: 'Số dư tài khoản',
        withdrawalMin: 'Số tiền rút tối thiểu: 50000 VND ',
        withdrawalMax: 'Số tiền rút tối đa: 999999999 VND ',
        WaitingForAllocation: 'Đang chờ phân phối',
        TreasureCollection: 'Bộ sưu tập Kho báu',
        EarnCommissionsTogether: 'Cùng nhau kiếm tiền hoa hồng',
        DayByDayEarning: 'Ngày qua ngày Kiếm tiền',
        StillHaveChanceToOrderToday: 'Hôm nay vẫn còn cơ hội đặt hàng',
        AgentLevel: 'Đại lý cấp',
        ApplicableMembers: 'Thành viên áp dụng',
        MembershipApplies: 'Thành viên áp dụng',
        AndAbove: 'trở lên',
        QuantityRange: 'Phạm vi số lượng',
        Rose: 'Hoa hồng',
        totalOrderCapital: 'tổng số vốn đặt hàng',
        MyAccount: 'Tài khoản của tôi',
        MembershipLevel: 'Cấp độ thành viên',
        UpdateLimits: 'Giới hạn cập nhật',
        RemainingFunds: 'Số tiền còn lại',
        GetItNow: 'Get it now',
        tip1: 'Ghi chú: Tất cả Đơn hàng sẽ được hệ thống eshop tự động phân phối ngẫu nhiên !',
        tip2: '-Giới hạn đơn hàng tùy thuộc vào cấp bậc thành viên của bạn.',
        tip3: '-Vốn lưu động trong tài khoản của bạn cần có tương đương với giá trị đơn hàng mới có thể xử lí đơn.',
        WaitingForDistribution: 'Đang chờ phân phối',
        NoMore: 'Không còn nữa',
        mission: 'nhiệm vụ',
    },
    guideText: {
        no1: '- Mua hàng trực tuyến mang lại sự tiện lợi, chủ động, lựa chọn đa dạng, với các dịch vụ hỗ trợ mua hàng, bán hàng, thanh toán an toàn, giao hàng chuyên nghiệp, Pinggo.vn  không ngừng nỗ lực, cải tiến đáp ứng nhu cầu mua sắm cho người tiêu dùng.',
        no2: '- Sản phẩm của PingGo cam kết chính hãng từ các nhà cung cấp, nhãn hàng với giá cả cạnh tranh. Khi đặt hàng tại PingGo bạn không cần phải lo lắng về chất lượng vì sản phẩm được đăng bán trên sàn đều đã được PingGo chọn lọc và kiểm tra cần thận.',
        no3: '- Chúng tôi phục vụ 7 ngày trong tuần.',
        no4: '- Quy định về công việc :',
        no5: '- Trong quá trình làm việc các thành viên phải làm theo trình tự và đúng với quy định ,yêu cầu của hệ thống .',
        no6: '- Pinggo.vn Xin Trân Trọng Cảm Ơn Các Thành Viên Đã Chung Tay Góp Sức Đồng Hành Cùng Công Ty Cổ Phần Pinggo .',
    },
    revision: {
        password: 'Mật khẩu',
        withdrawalPassword: 'Mật khẩu rút tiền',
        withdrawalAccount:'Tài khoản rút tiền',
        currentPassword: 'Mật khẩu hiện tại',
        aNewPassword: 'Mật khẩu mới',
        confirmYourPassword: 'Xác nhận mật khẩu của bạn',
        realName: 'Tên thật',
        userNAME: 'TÊN ĐĂNG NHẬP',
        bankAccount: 'Tài khoản ngân hàng',
        bankName: 'Tên ngân hàng',
        rechargeAmount: 'Số tiền nạp',
        CompleteRecharge: 'Hoàn tất nạp tiền',
        withdrawalAmount: 'Số tiền rút',
    },
    order: {
        all: 'Tất cả các',
        not: 'Chưa giải quyết',
        complete: 'Hoàn',
        frozen: 'Đông cứng',
    },
    input: {
        Account: 'tên tài khoản',
        Passkey: 'mật khẩu',
        real_name: 'Tên thật',
        bank_user: 'TÊN ĐĂNG NHẬP',
        bank_card: 'Tài khoản ngân hàng',
        bank_id: 'Tên ngân hàng',
        amount: 'Số tiền rút',
        pass_pay: 'Mật khẩu quỹ',
        pass_login: 'Mật khẩu hiện tại',
        new: 'mật khẩu mới',
        confirm: 'Xác nhận mật khẩu',
    },
    pageTxt: {
        order: {
            tab1: 'Tất cả các',
            tab2: 'Chưa giải quyết',
            tab3: 'Hoàn',
            tab4: 'Đông cứng',
            awaiting: 'Đang chờ phân phối ',
        },
        orderDetail: {
            orderValue : 'Giá trị đơn hàng',
            profitMoney: 'Tiền lợi nhuận',
            amountToBeReturned: 'Số tiền phải trả lại',
            codeOrders: 'Mã đơn hàng',
            receivingTime: 'Thời gian nhận',
            orderStatus: 'Trạng thái đặt hàng',
            submit: 'Gửi đơn',
        }
    }
}