<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div style="overflow: hidden; height: 100%; ">
            <div class="nav-box">
                <div class="van-nav-bar van-hairline--bottom" style="color: rgb(255, 255, 255) !important; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
                    <div class="van-nav-bar__content">
                        <div class="van-nav-bar__title van-ellipsis"> {{ $t('page.product') }}</div>
                    </div>
                </div>
            </div>
            <div class="main" style="overflow: hidden auto; height: calc(100% - 100px); margin-top: 45px;">
                <div class="task-main" style="background-image: url('/img/task_bg.png'); background-size: 100%; background-repeat: no-repeat; position: absolute ;">
                    <div>
                        <div class="flex-1 line-ht" style="position:relative;left:0px;top:0px;">
                            <div class="fz12" style="position:relative;left:-30px;top:305px;color:rgb(232, 90, 118);"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.TreasureCollection') }}</font></font></div>
                            <div class="fz12" style="position:relative;left:-30px;top:310px;color:rgb(232, 90, 118);"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.EarnCommissionsTogether') }}</font></font></div>
                        </div>
                        <div class="fz12 col-ff" style="width:75px; position:relative;left:250px;top:270px;color:#FFF;font-size: 12px;"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.DayByDayEarning') }}</font></font></div>
                    </div>
                    <div class="main-bottom">
                        <div class="fz12" style="position:relative;left:0px;top:315px;color:#FFFFFF;"><font>{{ $t('text.StillHaveChanceToOrderToday') }}</font></div>
                        <div class="gift-box">
                            <div class="img-box"><img src="/img/task_gift.png" alt=""></div>
                            <div class="img-box"><img src="/img/task_gift.png" alt=""></div>
                            <div class="img-box"><img src="/img/task_gift.png" alt=""></div>
                        </div>
                    </div>
                    <div class="main-footer">
                        <div class="footer-box">
                            <div class="top-block">
                                <div class="img-box">
                                    <div class="img-text"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.AgentLevel') }}  {{ api.grade }}</font></font></div>
                                    <img alt="" src="/img/vip1.png" class="img">
                                </div>
                                <div class="mg-lef15">
                                    <div class="mg-btm10">
                                        <div class="fz12 col-acc"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.ApplicableMembers') }}</font></font></div>
                                        <div class="fz14 font-wgh"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.MembershipApplies') }} VIP {{ api.grade }} {{$t('text.AndAbove') }} </font></font></div>
                                    </div>
                                    <div class="mg-btm10">
                                        <div class="fz12 col-acc"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.QuantityRange') }}</font></font></div>
                                        <div class="fz14 font-wgh"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{api.min}}VND To {{api.max}}VND</font></font></div>
                                    </div>
                                    <div>
                                        <div class="fz12 col-acc"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.Rose') }}</font></font></div>
                                        <div class="fz14 font-wgh"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{api.rate}}% {{ $t('text.totalOrderCapital') }}</font></font></div>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-block">
                                <div class="fz12 mg-tp5 mg-btm5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.MyAccount')}}</font></font></div>
                                <div class="term-box">
                                    <div class="fz14"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.MembershipLevel')}}： {{ api.grade }} </font></font></div>
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAB3klEQVRYR+3Wy07CQBQG4P8Ud0KfwMQHkcveldKaYCKi0bjVRzFujZd4i9HC3iVtfBGNL1C6hWOGSIS2QzqdUlh01tPp1//MmSlhxQetuA8FULdCRYJFgroJ6D6/Enuw7DZtEF0CGBqMM7/e/Zh82NKBpmsdMHAPoCRQTPgKat3NlQCGcWMg4ydodDeWDjS9ZpuZ7ibJ/YGGDG4F9Z6zVKAMR8THfq33ON1Yue9BKQ448uvdp3DX5wpUxQlsbsA0uNyAprfXZh5FGoIkZc11D+rgIgmafWubDboWrc+Mi6DuvOpcVbq4CLDsWt8ETA7JEUp8MtjqiVNeeZiefcjMt+FzLklZpSUOAcW8VEgpzqCOX3WeVb52posrXnMXTG8A1qYWUUJmlZz0Jln3rH2D8RBBEp0Oao7oROnIMrm5V10aZCyOMaSSelkTHTPlT7tFQxb34my5Y5KU4kAdv6G255SuuiTISt/qgHAz060iuQxwiW6Ssmu3CPFJYsSjReISAcUkKRLgSHIl49Cvvr+oHCXz5ib+WZAg/9ceN0S2uMQJThRS5IJwysDYci8QlwooHqq41g4DV+P/SYPOg6rTy2rPKR0zi3qpyrqJm0Rl0SznFkDdNIsEiwR1E9B9/hdcXww4Q5VScAAAAABJRU5ErkJggg==" alt="">
                                </div>
                                <div class="term-box">
                                    <div class="fz14"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.UpdateLimits')}}：{{api.tasks_complete}} / {{ api.tasks }} </font></font></div>
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAB3klEQVRYR+3Wy07CQBQG4P8Ud0KfwMQHkcveldKaYCKi0bjVRzFujZd4i9HC3iVtfBGNL1C6hWOGSIS2QzqdUlh01tPp1//MmSlhxQetuA8FULdCRYJFgroJ6D6/Enuw7DZtEF0CGBqMM7/e/Zh82NKBpmsdMHAPoCRQTPgKat3NlQCGcWMg4ydodDeWDjS9ZpuZ7ibJ/YGGDG4F9Z6zVKAMR8THfq33ON1Yue9BKQ448uvdp3DX5wpUxQlsbsA0uNyAprfXZh5FGoIkZc11D+rgIgmafWubDboWrc+Mi6DuvOpcVbq4CLDsWt8ETA7JEUp8MtjqiVNeeZiefcjMt+FzLklZpSUOAcW8VEgpzqCOX3WeVb52posrXnMXTG8A1qYWUUJmlZz0Jln3rH2D8RBBEp0Oao7oROnIMrm5V10aZCyOMaSSelkTHTPlT7tFQxb34my5Y5KU4kAdv6G255SuuiTISt/qgHAz060iuQxwiW6Ssmu3CPFJYsSjReISAcUkKRLgSHIl49Cvvr+oHCXz5ib+WZAg/9ceN0S2uMQJThRS5IJwysDYci8QlwooHqq41g4DV+P/SYPOg6rTy2rPKR0zi3qpyrqJm0Rl0SznFkDdNIsEiwR1E9B9/hdcXww4Q5VScAAAAABJRU5ErkJggg==" alt="">
                                </div>
                                <div class="term-box">
                                    <div class="fz14"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.RemainingFunds')}}：{{ api.balance }} VND</font></font></div>
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAB3klEQVRYR+3Wy07CQBQG4P8Ud0KfwMQHkcveldKaYCKi0bjVRzFujZd4i9HC3iVtfBGNL1C6hWOGSIS2QzqdUlh01tPp1//MmSlhxQetuA8FULdCRYJFgroJ6D6/Enuw7DZtEF0CGBqMM7/e/Zh82NKBpmsdMHAPoCRQTPgKat3NlQCGcWMg4ydodDeWDjS9ZpuZ7ibJ/YGGDG4F9Z6zVKAMR8THfq33ON1Yue9BKQ448uvdp3DX5wpUxQlsbsA0uNyAprfXZh5FGoIkZc11D+rgIgmafWubDboWrc+Mi6DuvOpcVbq4CLDsWt8ETA7JEUp8MtjqiVNeeZiefcjMt+FzLklZpSUOAcW8VEgpzqCOX3WeVb52posrXnMXTG8A1qYWUUJmlZz0Jln3rH2D8RBBEp0Oao7oROnIMrm5V10aZCyOMaSSelkTHTPlT7tFQxb34my5Y5KU4kAdv6G255SuuiTISt/qgHAz060iuQxwiW6Ssmu3CPFJYsSjReISAcUkKRLgSHIl49Cvvr+oHCXz5ib+WZAg/9ceN0S2uMQJThRS5IJwysDYci8QlwooHqq41g4DV+P/SYPOg6rTy2rPKR0zi3qpyrqJm0Rl0SznFkDdNIsEiwR1E9B9/hdcXww4Q5VScAAAAABJRU5ErkJggg==" alt="">
                                </div>
                            </div>
                            <div class="btn-box">
                                <div class="notAllow btn" style="line-height: 30px;"  @click="onSubmit">
                                    <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t('text.GetItNow') }}</font></font></div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-box">
                            <div class="fz12 text-lef mg-tp10 mg-btm5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.tip1')}}</font></font></div>
                            <div class="fz12 text-lef mg-btm10"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.tip2')}}</font></font></div>
                            <div class="fz12 text-lef mg-btm10"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('text.tip3')}}</font></font></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomMenu :place="2" />
    </div>
</template>
<script>
import BottomMenu from "@/components/computer/BottomMenu2.vue"

import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'

import { ref } from 'vue'
import tool from '@/assets/js/tool'
export default {
    components: {
        BottomMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            api: {
                grade: '',
                agent: '',
                balance: '',
                rate: '',
                min: '',
                max: '',
                tasks: '',
                tasks_complete: '',
            }
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.product') // 重置 setup 中的 title 要在重设置了语言之后
        this.getInfo()
        return {}
    },
    methods: {        
        // 得到这个用户的基本信息
        // 需要知道这个用户 当前的级别 完成任务的量 当前余额等信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    // console.log(response.data.data.grade)
                    this.api.balance = response.data.data.balance
                    this.api.grade = response.data.data.grade
                    // let vip = response.data.data.grade
                    // if (1===vip) this.api.agent = '6'
                    // if (2===vip) this.api.agent = '5'
                    // if (3===vip) this.api.agent = '4'
                    // if (4===vip) this.api.agent = '3'
                    // if (5===vip) this.api.agent = '2'
                    // if (6===vip) this.api.agent = '1'
                    this.api.rate = response.data.data.rate
                    this.api.min = response.data.data.min
                    this.api.max = response.data.data.max
                    this.api.tasks = response.data.data.tasks
                    this.api.tasks_complete = response.data.data.tasks_complete
                } else {
                    // layer.msg(response.data.msg)
                    // localStorage.removeItem('token')
                    // this.$router.go(0) // 刷新本页
                    // 跳转
                    this.$router.push({
                        path: '/login',
                        query: {
                            msg: 'account out'
                        }
                    })
                }
            })
            .catch(function (error) {
                // 没有权限就跳到登录页
                console.log(error)
            })
        }, // End 得到这个用户的基本信息
        // 提交提现申请
        onSubmit() {
            axios.post(
                '/api/m/rush/to/buy', 
                {

                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    this.$router.push({
                        // /order/detail?orders_id=35&status=0&number=OR2411051730796287&date=1730796287&amount=0&commission=0&commodity_id=0&icon=&name=&shop=
                        path: '/order/detail',
                        query: {
                            orders_id: response.data.data.orders_id,
                            status: response.data.data.status,
                            number: response.data.data.number,
                            amount: response.data.data.amount,
                            commission: response.data.data.commission,
                            commodity_id: response.data.data.commodity_id,
                            icon: '',
                            name: '',
                            shop: '',
                            date: response.data.data.created_at,
                        }
                    })
                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()

    }
}
</script>
<style lang="scss" scoped>
.task-main .main-top {
    padding: 7.6rem 55px 45px;
}
.main-footer {
    margin-top: 0px;
}
.gift-box {
    margin-top: 315px;
}
.task-main .main-footer {
    margin-bottom: 80px;
}

</style>