<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div data-v-95164b0e="">
            <div data-v-95164b0e="" class="nav-box">
                <div data-v-95164b0e="" class="van-nav-bar van-hairline--bottom" style="color: rgb(255, 255, 255) !important; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
                    <div class="van-nav-bar__content">
                        <router-link to="#" @click="$router.back()">
                        <div class="van-nav-bar__left"><i class="van-icon van-icon-arrow-left van-nav-bar__arrow"><!----></i></div>
                        </router-link>
                        <div class="van-nav-bar__title van-ellipsis">{{ title }}</div>
                    </div>
                </div>
            </div>
            <div data-v-95164b0e="" style="overflow: hidden;">
                <div data-v-95164b0e="" class="CapitalList" style="overflow: hidden auto; margin-top: 50px;">
                    <div data-v-95164b0e="" class="van-pull-refresh">
                        <div class="van-pull-refresh__track" style="transition-duration: 0ms;">
                            <div class="van-pull-refresh__head"></div>
                            <div data-v-95164b0e="" role="feed" class="van-list">
                                <div data-v-95164b0e="">
                                    <div data-v-95164b0e="">
                                        <div data-v-95164b0e="" class="capital-item" style="cursor: pointer;">
                                            <div data-v-95164b0e="" class="money-text col-f41" style="float: left; width: 40%;"> +30000 </div>
                                            <div data-v-95164b0e="" style="float: right; width: 60%; text-align: right; padding-right: 20px;"></div>
                                            <div data-v-95164b0e="" style="clear: both;"></div>
                                            <div data-v-95164b0e="" class="fz12 col-acc"><font data-v-95164b0e="" style="vertical-align: inherit;"><font data-v-95164b0e="" style="vertical-align: inherit;">TZ2410162047487759</font></font></div>
                                            <div data-v-95164b0e="" class="fz12 col-acc"> 2024-10-16 21:47:48 </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="van-list__finished-text">Không còn nữa</div>
                                <div class="van-list__placeholder"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
// import topBox from "../components/TopBox.vue"
// import mainMenu from "../components/MainMenu.vue"
import { ref } from 'vue'
import tool from '@/assets/js/tool'
export default {
    components: {
        // topBox,
        // mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.financialDetails') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    }
}
</script>
<style lang="scss" scoped>
</style>