
<template>
    <div class="QYZX"><!-- 与#app同一个div中的class要迁移过来 -->
        <div>
            <div class="van-nav-bar van-hairline--bottom" style="color: rgb(255, 255, 255) !important; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
                <div class="van-nav-bar__content">
                    <div class="van-nav-bar__left"><router-link to="#" @click="$router.back()"><i class="van-icon van-icon-arrow-left van-nav-bar__arrow"></i></router-link></div>
                    <div class="van-nav-bar__title van-ellipsis">{{title}}</div>
                </div>
            </div>
            <div>
                <div class="van-cell-group van-hairline--top-bottom">
                    <div class="title-changepwd">{{ $t('revision.currentPassword') }}</div>
                    <div class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="password" :placeholder="$t('revision.currentPassword')" v-model="input.pass_login" class="van-field__control"></div>
                        </div>
                    </div>
                    <div class="title-changepwd">{{ $t('revision.aNewPassword') }}</div>
                    <div class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="password" :placeholder="$t('revision.aNewPassword')" v-model="input.new" class="van-field__control"></div>
                        </div>
                    </div>
                    <div class="title-changepwd">{{ $t('revision.confirmYourPassword') }}</div>
                    <div class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body"><input type="password" :placeholder="$t('revision.confirmYourPassword')" v-model="input.confirm" class="van-field__control"></div>
                        </div>
                    </div>
                </div>
                <button @click="onSubmit" class="van-button van-button--info van-button--normal" style="width: 80%; margin-top: 65px; border-radius: 15px;"><div class="van-button__content"><span class="van-button__text">{{ $t('but.continue') }}</span></div></button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import tool from '@/assets/js/tool'
export default {
    components: {
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            input: {
                pass_login: '',
                new: '',
                confirm: '',
            }
        }
    },
    mounted() {
        // 这个页面需要有令牌才可以展示
        tool().checkToken(this.$router) 
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        
        this.title = this.$t('page.password') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    },
    methods: {
        onSubmit() {
            axios.post(
                '/api/m/edit/login', 
                {
                    pass_login: this.input.pass_login,
                    new: this.input.new,
                    confirm: this.input.confirm,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    
                } else {
                    layer.msg( this.$t('input.'+response.data.data.id) + ' ' + response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()
    }
}
</script>
<style lang="scss" scoped>

</style>