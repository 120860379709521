// 进口路由
import { createRouter, createWebHistory } from "vue-router"
// 进口仪表板视图模板


// 登录后的模板

// 商品页面
// import MainProduct from '@/pages/MainProduct'
import MainProduct from '@/pages/computer/MainProduct'
// 编辑用户信息
// import UserRevision from '@/pages/UserRevision'
import UserRevision from '@/pages/computer/UserRevision'
// 用户手册
// import UserGuide from '@/pages/UserGuide'
import UserGuide from '@/pages/computer/UserGuide'
// 公司介绍
// import CompanyProfile from '@/pages/CompanyProfile'
import CompanyProfile from '@/pages/computer/CompanyProfile'
// import UserServe from '../pages/UserServe'
import UserServe from '@/pages/computer/UserServe'
import OnlineService from '../pages/OnlineService'
// 资金明细
// import FinancialDetails from '../pages/FinancialDetails'
import FinancialDetails from '@/pages/computer/FinancialDetails'
// 用户充值引寻
// import UserRecharge from '@/pages/UserRecharge'
import UserRecharge from '@/pages/computer/UserRecharge'
// 用户充值
// import UserRecharge2 from '@/pages/UserRecharge2'
import UserRecharge2 from '@/pages/computer/UserRecharge2'
// 用户提款
// import UserWithdraw from '@/pages/UserWithdraw'
import UserWithdraw from '@/pages/computer/UserWithdraw'
// 编辑登录密码
// import EditPassword from '@/pages/EditPassword'
import EditPassword from '@/pages/computer/EditPassword'
// 编辑资金密码
// import WithdrawalPassword from '@/pages/WithdrawalPassword'
import WithdrawalPassword from '@/pages/computer/WithdrawalPassword'
// 编辑收款帐号
// import WithdrawalAccount from '@/pages/WithdrawalAccount'
import WithdrawalAccount from '@/pages/computer/WithdrawalAccount'
// 抢单成功
import OrderSuccess from '../pages/OrderSuccess'
// 订单详情
// import DetailOrder from '@/pages/DetailOrder'
import DetailOrder from '@/pages/computer/DetailOrder'
// 电脑端首页-旧的
// import computerHome from '@/pages/computer/IndexHome'
// 电脑端首页-新的 按财神的要求来
import computerHome from '@/pages/computer/NewHome'
// 手机端首页
import home from '@/pages/IndexHome'
// 电脑端登录页
// import computerLogin from '@/pages/computer/LoginUser'
import computerLogin from '@/pages/computer/LoginAccount'
// 手机端登录页
import login from '@/pages/LoginUser'
// 电脑端注册页
// import computerRegister from '@/pages/computer/RegisterUser'
import computerRegister from '@/pages/computer/RegisterAccount'
// 手机端注册页
import register from '../pages/RegisterUser'
// 电脑端用户页
// import computerUser from '@/pages/computer/UserMain'
import computerUser from '@/pages/computer/UserIndex'
// 手机端用户页
// import user from '@/pages/UserMain'
import user from '@/pages/computer/UserIndex'
// 任务订单列表之全部
// import OrderList from '@/pages/OrderList'
import OrderList from '@/pages/computer/OrderList'
// 任务订单列表之等待
import OrderWaiting from '@/pages/computer/OrderWaiting'
// 任务订单列表之完成
import OrderCompletion from '@/pages/computer/OrderCompletion'
// 任务订单列表之冻结
import OrderFreeze from '@/pages/computer/OrderFreeze'


// 电脑端发布付费文章
import computerPublishArticle from '@/pages/computer/PublishArticle'


import i18n from '../language'


const routes = [
    {
        name: 'Home',
        // 路由的 URL 字串
        path: '/',
        component: home,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        name: 'computerHome',
        // 路由的 URL 字串
        path: '/computer',
        component: computerHome,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        name: 'Login',
        // 路由的 URL 字串
        path: '/login',
        component: login,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'computerLogin',
        // 路由的 URL 字串
        path: '/computer/login',
        component: computerLogin,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'Register',
        path: '/register',
        component: register,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'computerRegister',
        path: '/computer/register',
        component: computerRegister,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'MainProduct',
        path: '/product',
        component: MainProduct,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['product']
        }
    },
    {
        // PC 端产品页面
        name: 'PcProduct',
        path: '/computer/product',
        component: MainProduct,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['product']
        }
    },
    {
        name: 'User',
        path: '/user',
        component: user,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'computerUser',
        path: '/computer/user',
        component: computerUser,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        // 任务订单列表之全部
        name: 'Order',
        path: '/order',
        component: OrderList,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        // 任务订单列表之等待
        name: 'OrderWaiting',
        path: '/order/waiting',
        component: OrderWaiting,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        // 任务订单列表之完成
        name: 'OrderCompletion',
        path: '/order/completion',
        component: OrderCompletion,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        // 任务订单列表之冻结
        name: 'OrderFreeze',
        path: '/order/freeze',
        component: OrderFreeze,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },


    {
        name: 'UserRevision',
        path: '/revision',
        component: UserRevision,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['revision']
        }
    },
    {
        name: 'UserGuide',
        path: '/guide',
        component: UserGuide,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['guide']
        }
    },
    {
        name: 'CompanyProfile',
        path: '/company/profile',
        component: CompanyProfile,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['companyProfile']
        }
    },
    {
        name: 'UserServe',
        path: '/serve',
        component: UserServe,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['serve']
        }
    },
    {
        name: 'OnlineService',
        path: '/online/service',
        component: OnlineService,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['onlineService']
        }
    },
    {
        name: 'FinancialDetails',
        path: '/financial/details',
        component: FinancialDetails,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['financialDetails']
        }
    },
    {
        name: 'UserRecharge',
        path: '/recharge',
        component: UserRecharge,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['recharge']
        }
    },
    {
        name: 'UserRecharge2',
        path: '/recharge2',
        component: UserRecharge2,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['recharge']
        }
    },
    {
        name: 'UserWithdraw',
        path: '/withdraw',
        component: UserWithdraw,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['withdraw']
        }
    },
    {
        // 编辑帐户登录密码
        name: 'EditPassword',
        path: '/edit/password',
        component: EditPassword,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['password']
        }
    },
    {
        // 编辑资金密码
        name: 'WithdrawalPassword',
        path: '/withdraw/password',
        component: WithdrawalPassword,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['withdraw']
        }
    },
    {
        name: 'WithdrawalAccount',
        path: '/withdraw/account',
        component: WithdrawalAccount,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['withdraw']
        }
    },
    {
        name: 'OrderSuccess',
        path: '/order/success',
        component: OrderSuccess,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['OrderSuccess']
        }
    },
    {
        name: 'DetailOrder',
        path: '/order/detail',
        component: DetailOrder,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['OrderDetail']
        }
    },
    // 电脑端发布付费文章
    {
        name: 'computerPublishArticle',
        path: '/computer/publish/article',
        component: computerPublishArticle,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['PublishArticle']
        }
    },



]

const router = Router()
export default router
function Router() {
    // console.log( localStorage.getItem("lang") ) 
    // console.log( i18n.global.locale.value )
    const router = new createRouter({
        history: createWebHistory(),
        routes
    })
    return router
}