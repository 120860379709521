<template>
    <div class="Footer">
        <div class="van-row" style="margin-top: 8px;">
            <div class="van-col van-col--8">
                <router-link to="/computer" :class="selectedHome">
                    <div><i class="van-icon van-icon-shopping-cart-o"></i><p>{{ $t('footer.homePage') }}</p></div>
                </router-link>
            </div>
            <div class="van-col van-col--8">
                <router-link to="/computer/product" :class="selectedTask">
                    <div><i class="van-icon van-icon-notes-o"></i><p>{{ $t('footer.product') }}</p></div>
                </router-link>
            </div>
            <div class="van-col van-col--8">
                <router-link to="/computer/user" :class="selectedUser">
                    <div><i class="van-icon van-icon-friends-o"></i><p>{{ $t('footer.mine') }}</p></div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 接收参数
    props: { 
        place: String 
    },
    data() {
        return {
            // selectedHome: '',
            // selectedTask: '',
            // selectedUser: '',
        }
    },
    setup(props, context) {
        let selectedHome = ''
        let selectedTask = ''
        let selectedUser = ''
        switch(props.place) {
            case 1:
                selectedHome = 'selected'
                break;
            case 2: 
                selectedTask = 'selected'
                break;
            case 3:
                selectedUser = 'selected'
                break;
        }
        //attrs传递的参数    msg1: '我是父组件传递的信息1'
        console.log({ ...context.attrs })
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false
        return {
            token,
            selectedHome,  // 这里将 selectedHome 返回则 data(){...} 中不需要再声明 selectedHome 变量
            selectedTask,
            selectedUser
        }
    },

}
</script>

<style>
</style>